import React, { FC, useMemo } from "react";
import {
  DocumentInterface,
  PaymentStatusEnum,
  ShipmentInterface,
} from "../../interfaces";
import { currencyExchangeText, useCurrencyExchanges } from "../../utils";

interface DocumentTotalProps {
  shipment: ShipmentInterface;
  document?: DocumentInterface;
}

const DocumentTotal: FC<DocumentTotalProps> = ({ shipment, document }) => {
  const exchanges = useCurrencyExchanges();

  const subTotal = useMemo(() => {
    return (
      document?.subTotal ??
      shipment.items.reduce((acc, item) => {
        return acc + item.rate.value;
      }, 0)
    );
  }, [document, shipment.items]);

  const iva = useMemo(() => {
    return (
      document?.iva ??
      shipment.items.reduce((acc, item) => {
        return acc + item.rate.iva;
      }, 0)
    );
  }, [shipment.items, document]);

  const ipostel = useMemo(() => {
    return (
      document?.ipostel ??
      shipment.items.reduce((acc, item) => {
        return acc + item.rate.ipostel;
      }, 0)
    );
  }, [shipment.items, document]);

  const total = useMemo(() => {
    return subTotal + iva + ipostel;
  }, [subTotal, iva, ipostel]);

  const debt = useMemo(() => {
    const totalPaid =
      document?.payments
        .filter(
          (p) =>
            p.status === PaymentStatusEnum.APPROVE ||
            p.status === PaymentStatusEnum.PENDING
        )
        .reduce((acc, p) => acc + p.amount, 0) ?? 0;

    return total - totalPaid;
  }, [total, document]);

  return (
    <div className="flex flex-row gap-4 w-full">
      <div className="flex flex-1 flex-col">
        <div className="flex flex-row justify-between">
          <p className="text-gray-400">Sub total</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold leading-tight text-gray-600">
              {currencyExchangeText(subTotal, exchanges, "BS")}
            </p>
            <p className="font-semibold leading-tight text-gray-400 text-sm">
              {currencyExchangeText(subTotal, exchanges, "USD")}
            </p>
          </div>
        </div>
        <hr className="my-2" />

        <div className="flex flex-row justify-between">
          <p className="text-gray-400">IVA</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold leading-tight text-gray-600">
              {currencyExchangeText(iva, exchanges, "BS")}
            </p>
            <p className="font-semibold leading-tight text-gray-400 text-sm">
              {currencyExchangeText(iva, exchanges, "USD")}
            </p>
          </div>
        </div>
        <hr className="my-2" />

        <div className="flex flex-row justify-between">
          <p className="text-gray-400">Ipostel</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold leading-tight text-gray-600">
              {currencyExchangeText(ipostel, exchanges, "BS")}
            </p>
            <p className="font-semibold leading-tight text-gray-400 text-sm">
              {currencyExchangeText(ipostel, exchanges, "USD")}
            </p>
          </div>
        </div>
        <hr className="my-2" />

        {!!document?.igtfAmount && (
          <>
            <div className="flex flex-row justify-between">
              <p className="text-gray-400">IGTF</p>
              <div className="flex flex-col items-end">
                <p className="font-semibold leading-tight text-gray-600">
                  {currencyExchangeText(
                    document?.igtfAmount ?? 0,
                    exchanges,
                    "BS"
                  )}
                </p>
                <p className="font-semibold leading-tight text-gray-400 text-sm">
              {currencyExchangeText(document?.igtfAmount ?? 0, exchanges, "USD")}
            </p>
              </div>
            </div>
            <hr className="my-2" />
          </>
        )}

        <div className="flex flex-row justify-between">
          <p className="text-gray-400">Total de factura</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold leading-tight text-gray-600">
              {currencyExchangeText(total, exchanges, "BS")}
            </p>
            <p className="font-semibold leading-tight text-gray-400 text-sm">
              {currencyExchangeText(total, exchanges, "USD")}
            </p>
          </div>
        </div>

        <div className="flex items-end flex-col gap-4 mt-4">
          <p className="text-gray-400 text-sm">Total por pagar:</p>

          <div className="flex flex-col items-end">
            <p className="font-semibold leading-tight text-gray-800 text-2xl">
              {currencyExchangeText(debt, exchanges, "BS")}
            </p>
            <p className="font-semibold leading-tight text-gray-500 text-lg">
              {currencyExchangeText(debt, exchanges, "USD")}
            </p>
          </div>
        </div>

        <div className="flex-1 mt-4">
          <p className="text-right text-sm text-gray-700">
            Tasa de cambio BCV:{" "}
            {currencyExchangeText(1, exchanges, "BS", "USD")}
          </p>
          <p className="w-full italic text-right text-xs text-gray-500">
            Los montos en bolívares se calculan a la tasa de <br />
            cambio del Banco Central de Venezuela (BCV) <br />
            vigente al día.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DocumentTotal;
