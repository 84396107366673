import { FC, useEffect, useState } from "react";
import {
  FormDatePicker,
  FormSelect,
  FormTextInput,
} from "../../components/FormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { formatFloat } from "../../utils";
import { LinkText, PrimaryButton } from "../../components/Buttons";
import classNames from "classnames";
import moment from "moment";
import {
  Banco,
  PaymentInterface,
  PaymentMethodEnum,
  PaymentStatusEnum,
} from "../../interfaces";
import Modal from "../../components/Modal";
import P2CProblemForm from "./P2CProblemForm";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { getBankList } from "../../services";

const IDENTIFICATION_TYPES = ["V", "E", "P", "J", "G"];

export enum PaymentDateEnum {
  LAST_4_DAYS = "Últimos 3 días",
  LAST_8_DAYS = "Últimos 7 días",
  SPECIFIC_DATE = "Fecha específica",
}

interface P2CPaymentFormProps {
  remaining: number;
  shipmentNumber: string;
  onPay: (
    payment: PaymentInterface,
    avoidVerifications?: boolean
  ) => Promise<{
    error: boolean;
    message: string;
  }>;
}

const P2CPaymentForm: FC<P2CPaymentFormProps> = ({
  onPay,
  remaining,
  shipmentNumber,
}) => {
  const [loading, setLoading] = useState(false);
  const [bankList, setBankList] = useState<Banco[]>([]);
  const [openProblemModal, setOpenProblemModal] = useState(false);
  const [openProblemSentModal, setOpenProblemSentModal] = useState(false);
  const validationSchema = Yup.object().shape({
    identificationType: Yup.string().required("Este campo es requerido"),
    clientIdentifier: Yup.string().required("Este campo es requerido"),
    phoneNumber: Yup.string().required("Este campo es requerido"),
    reference: Yup.string().required("Este campo es requerido"),
    amount: Yup.number()
      .required("Este campo es requerido")
      .max(remaining, "El monto no puede ser mayor al saldo restante"),
  });

  useEffect(() => {
    const afunction = async () => {
      const bankList = await getBankList();
      if (bankList.didError || !bankList.model) {
        return;
      }
      setBankList(bankList.model);
    }

    afunction();
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        identificationType: "V",
        clientIdentifier: "",
        phoneNumber: "",
        amount: remaining > 0.01 ? remaining.toFixed(2) : "0",
        reference: "",
        dateType: PaymentDateEnum.LAST_8_DAYS,
        date: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const payment: PaymentInterface = {
          status: PaymentStatusEnum.APPROVE,
          isRetention: false,
          amount: +values.amount,
          paymentAmount: +values.amount,
          clientIdentifier: values.identificationType + values.clientIdentifier,
          paymentMethod: {
            paymentMethodID: PaymentMethodEnum.PAGO_MOVIL_P2C,
            paymentMethodName: "Pago Móvil P2C",
            currencyID: 1,
          },
          destBankID: 4,
          phone: values.phoneNumber,
          reference: values.reference,
          igtfAmount: 0,
          igtfPercentage: 0,
          paymentDate:
            values.dateType === PaymentDateEnum.SPECIFIC_DATE
              ? values.date
              : new Date().toISOString(),
          attempts:
            values.dateType === PaymentDateEnum.LAST_4_DAYS
              ? 4
              : values.dateType === PaymentDateEnum.LAST_8_DAYS
              ? 8
              : 1,
        };
        const response = await onPay(payment);
        if (!response.error) {
          resetForm();
        }
        setLoading(false);
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <div className="flex gap-4 flex-col sm:flex-row">
            <div className="flex flex-1">
              <div className="flex flex-col">
                <div>
                  <FormSelect
                    containerClassName="rounded rounded-r-none"
                    name="identificationType"
                    type="text"
                    label="Tipo"
                    options={IDENTIFICATION_TYPES}
                    optionString={(identificationTypes: string) =>
                      identificationTypes
                    }
                    selected={formik.values.identificationType}
                    onSelectOption={(value) => {
                      formik.setFieldValue("identificationType", value);
                    }}
                  />
                </div>
              </div>

              <div className="flex w-full flex-col">
                <div>
                  <FormTextInput
                    className="rounded rounded-l-none"
                    name="clientIdentifier"
                    type="text"
                    label="Cédula del cliente"
                    placeholder="12345678"
                    onChange={formik.handleChange}
                    value={formik.values.clientIdentifier}
                    error={
                      formik.touched.clientIdentifier &&
                      formik.errors.clientIdentifier
                        ? formik.errors.clientIdentifier
                        : ""
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-1 flex-col">
              <div>
                <FormTextInput
                  name="phoneNumber"
                  type="text"
                  label="Número de teléfono"
                  placeholder="04121234567"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  error={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? formik.errors.phoneNumber
                      : ""
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex gap-4 flex-col sm:flex-row">
            <div className="flex flex-1 flex-col">
              <div>
                <FormTextInput
                  name="amount"
                  type="text"
                  label="Monto"
                  placeholder="0.00"
                  onChange={(e) => {
                    formatFloat(e.target.value, (v) =>
                      formik.setFieldValue("amount", v)
                    );
                  }}
                  value={formik.values.amount}
                  error={
                    formik.touched.amount && formik.errors.amount
                      ? formik.errors.amount
                      : ""
                  }
                />
              </div>
            </div>

            <div className="flex flex-1 flex-col justify-start">
              <div>
                <FormTextInput
                  name="reference"
                  type="text"
                  label="Referencia"
                  placeholder="Ingrese los últimos 4 dígitos"
                  onChange={formik.handleChange}
                  value={formik.values.reference}
                  error={
                    formik.touched.reference && formik.errors.reference
                      ? formik.errors.reference
                      : ""
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex gap-4 flex-col sm:flex-row justify-end">
            <div className="flex flex-1 flex-col hidden">
              <FormSelect
                name="dateType"
                type="text"
                label="Fecha"
                options={[
                  PaymentDateEnum.LAST_4_DAYS,
                  PaymentDateEnum.LAST_8_DAYS,
                  PaymentDateEnum.SPECIFIC_DATE,
                ]}
                optionString={(type) => type}
                selected={formik.values.dateType}
                onSelectOption={(value) => {
                  formik.setFieldValue("dateType", value);
                }}
              />
            </div>

            <div
              className={classNames(
                "flex flex-col flex-1 justify-end",
                formik.values.dateType !== PaymentDateEnum.SPECIFIC_DATE &&
                  "hidden"
              )}
            >
              <FormDatePicker
                id="date"
                name="date"
                label=""
                useRange={false}
                maxDate={new Date()}
                minDate={moment().subtract(30, "days").toDate()}
                placeholder="Elegir fecha"
                error={
                  formik.touched.date && formik.errors.date
                    ? formik.errors.date
                    : undefined
                }
                value={{
                  startDate: formik.values.date
                    ? moment(formik.values.date).toDate()
                    : null,
                  endDate: formik.values.date
                    ? moment(formik.values.date).toDate()
                    : null,
                }}
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-indigo-600")
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    "date",
                    !!e?.startDate ? moment(e.startDate).toISOString() : null
                  );
                }}
                configs={{
                  shortcuts: {},
                }}
              />
            </div>

            <div className="flex items-end my-2">
              <PrimaryButton
                className="w-full mt-6 sm:w-32 sm:mt-0"
                disabled={loading}
              >
                Reportar
              </PrimaryButton>
            </div>
          </div>

          <div className="flex justify-end flex-col sm:flex-row">
            <div className="text-center sm:text-right text-xs text-gray-500 mt-4 sm:mt-0">
              <p className="text-balance max-w-[35rem]">
                Es posible verificar operaciones con una antigüedad máxima de
                siete (7) días.
              </p>
            </div>
          </div>

          <div className="flex justify-center md:justify-end">
            <LinkText
              className="text-sm"
              text="Tengo un problema con mi pago"
              onClick={() => setOpenProblemModal(true)}
            />
          </div>

          <Modal
            openModal={openProblemModal}
            setOpenModal={setOpenProblemModal}
          >
            <P2CProblemForm
              shipmentNumber={shipmentNumber}
              identificationType={formik.values.identificationType}
              clientIdentifier={formik.values.clientIdentifier}
              phoneNumber={formik.values.phoneNumber}
              reference={formik.values.reference}
              bankList={bankList}
              onCancel={() => setOpenProblemModal(false)}
              onSubmit={() => {
                setOpenProblemModal(false);
                setOpenProblemSentModal(true);
              }}
            />
          </Modal>

          <Modal
            openModal={openProblemSentModal}
            setOpenModal={setOpenProblemSentModal}
          >
            <div className="flex flex-col items-center justify-center max-w-[30rem]">
              <div className="flex flex-col items-center justify-center w-full">
                <CheckCircleIcon className="text-green-600 w-32 h-32" />
              </div>

              <p className="mt-2 text-lg font-medium text-gray-900">
                Su solicitud ha sido registrada
              </p>

              <p className="text-center text-balance mt-2 text-gray-700 text-sm">
                Le llegará a su correo la confirmación con el número de ticket
                para el seguimiento. Será atendida en las próximas 24 horas
                hábiles. Nuestro horario de atención es de lunes a viernes de
                8:00 am a 4:00 pm.
              </p>

              <div className="flex w-full justify-center items-center mt-6">
                <PrimaryButton
                  onClick={() => setOpenProblemSentModal(false)}
                  className="!px-8"
                >
                  Aceptar
                </PrimaryButton>
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default P2CPaymentForm;
