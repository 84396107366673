import * as Yup from "yup";
import { FC } from "react";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { Formik, FormikProps } from "formik";
import {
  FormFileUpload,
  FormSelect,
  FormTextAreaInput,
  FormTextInput,
} from "../../components/FormFields";
import { loaderService, sendPaymentErrorReport } from "../../services";
import { Banco } from "../../interfaces";

const IDENTIFICATION_TYPES = ["V", "E", "P", "J", "G"];

interface P2CProblemFormValues {
  email: string;
  description: string;
  identificationType: string;
  clientIdentifier: string;
  bank: Banco | null;
  phoneNumber: string;
  reference: string;
  proofOfPayment: File | null;
}
interface P2CProblemFormProps {
  shipmentNumber: string;
  identificationType: string;
  clientIdentifier: string;
  phoneNumber: string;
  reference: string;
  bankList: Banco[];
  onCancel: () => void;
  onSubmit: () => void;
}
const P2CProblemForm: FC<P2CProblemFormProps> = ({
  shipmentNumber,
  identificationType,
  clientIdentifier,
  phoneNumber,
  reference,
  bankList,
  onCancel,
  onSubmit,
}) => {
  const loading = loaderService.useIsLoading();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .transform((value) => value.trim())
      .email("Ingrese un correo válido")
      .required("El correo electrónico es requerido"),
    identificationType: Yup.string().required(
      "El tipo de identificación es requerido"
    ),
    clientIdentifier: Yup.string().required(
      "La identificación del cliente es requerida"
    ),
    bank: Yup.object().required("El banco emisor es requerido"),
    phoneNumber: Yup.string().required("El número de teléfono es requerido"),
    reference: Yup.string().required("La referencia es requerida"),
    proofOfPayment: Yup.mixed<File>()
      .required("El comprobante de pago es requerido")
      .test(
        "is-pdf-or-image",
        "Los archivos deben ser formato PDF o imagen",
        (file) => {
          if (file) {
            return (
              file.type === "application/pdf" || file.type.startsWith("image/")
            );
          }
          return false;
        }
      ),
  });

  const handleFileChange = (
    file: File[],
    formik: FormikProps<P2CProblemFormValues>
  ) => {
    if (file[0]) {
      formik.setFieldValue("proofOfPayment", file[0] ?? null);
    }
  };

  return (
    <div>
      <h3 className="text-gray-700 font-semibold text-lg mb-6">
        Reportar problema con el pago
      </h3>

      <Formik<P2CProblemFormValues>
        enableReinitialize
        initialValues={{
          email: "",
          description: "",
          proofOfPayment: null,
          identificationType,
          clientIdentifier,
          bank: null,
          phoneNumber,
          reference,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          loaderService.start();
          const response = await sendPaymentErrorReport(
            shipmentNumber,
            values.email,
            values.description,
            values.identificationType,
            values.clientIdentifier,
            values.bank!.descripcion!,
            values.phoneNumber,
            values.reference,
            values.proofOfPayment!
          );
          loaderService.stop();

          if (response.didError) {
            return;
          }

          onSubmit();
        }}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-4 w-full max-w-[25rem]"
          >
            <div>
              <FormTextInput
                name="email"
                label="Correo electrónico"
                placeholder="example@example.com"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email ? formik.errors.email : ""}
              />
            </div>

            <div>
              <FormTextAreaInput
                rows={3}
                name="description"
                label="Descripción del problema"
                onChange={formik.handleChange}
                value={formik.values.description}
                error={
                  formik.touched.description ? formik.errors.description : ""
                }
              />
            </div>

            <h3 className="text-gray-700 font-semibold mt-6">
              Datos de la cuenta pagadora
            </h3>

            <div className="flex flex-1">
              <div className="flex flex-col">
                <div>
                  <FormSelect
                    containerClassName="rounded rounded-r-none"
                    name="identificationType"
                    type="text"
                    label="Tipo"
                    options={IDENTIFICATION_TYPES}
                    optionString={(identificationTypes: string) =>
                      identificationTypes
                    }
                    selected={formik.values.identificationType}
                    onSelectOption={(value) => {
                      formik.setFieldValue("identificationType", value);
                    }}
                  />
                </div>
              </div>

              <div className="flex w-full flex-col">
                <div>
                  <FormTextInput
                    className="rounded rounded-l-none"
                    name="clientIdentifier"
                    label="Cédula del cliente"
                    placeholder="12345678"
                    onChange={formik.handleChange}
                    value={formik.values.clientIdentifier}
                    error={
                      formik.touched.clientIdentifier
                        ? formik.errors.clientIdentifier
                        : ""
                    }
                  />
                </div>
              </div>
            </div>

            <div>
              <FormSelect
                name="bank"
                label="Banco"
                options={bankList}
                optionString={(bank: Banco | null) => bank?.descripcion ?? ""}
                selected={formik.values.bank}
                error={formik.touched.bank ? formik.errors.bank : ""}
                onSelectOption={(value) => {
                  formik.setFieldValue("bank", value);
                }}
              />
            </div>

            <div>
              <FormTextInput
                name="phoneNumber"
                type="text"
                label="Número de teléfono"
                placeholder="04121234567"
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                error={
                  formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
                }
              />
            </div>

            <div>
              <FormTextInput
                name="reference"
                type="text"
                label="Referencia"
                placeholder="Ingrese los últimos 4 dígitos"
                onChange={formik.handleChange}
                value={formik.values.reference}
                error={formik.touched.reference ? formik.errors.reference : ""}
              />
            </div>

            <div>
              <p className="block text-m font-medium leading-6 text-gray-900 mb-2 truncate">
                Adjuntar comprobante de pago
              </p>

              <FormFileUpload
                className="flex block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                description="Se debe adjuntar un archivo en formato PDF o imagen"
                onSelectFile={(f) => handleFileChange(f, formik)}
                error={
                  formik.touched.proofOfPayment
                    ? formik.errors.proofOfPayment
                    : ""
                }
              />
            </div>

            <div className="flex w-full justify-between items-center mt-6">
              <SecondaryButton
                type="button"
                onClick={onCancel}
                className="!px-6"
              >
                Cancelar
              </SecondaryButton>

              <PrimaryButton type="submit" disabled={loading} className="!px-6">
                Aceptar
              </PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default P2CProblemForm;
