import React, { FC, useEffect, useState } from "react";
import logo from "../../assets/LogoTEALCA.svg";
import PriceCalculatorForm, {
  PIECES_INITIAL_VALUES,
  PriceCalculatorFormValues,
} from "./PriceCalculatorForm";
import PriceCalculatorResume from "./PriceCalculatorResume";
import {
  DeliveryType,
  ItemInterface,
  LocationInterface,
  PaymentMode,
  PieceInterface,
  ShipmentService,
  UserInterface,
} from "../../interfaces";
import {
  CreateOrUpdateSession,
  getWeights,
  loaderService,
  userLogin,
} from "../../services";
import classNames from "classnames";
import { useAppSelector } from "../../store/hooks";

const PriceCalculator: FC = () => {
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const pieceCategories = useAppSelector(
    (state) => state.inmutable.pieceCategories
  );

  const url = new URL(window.location.href);
  const hideLogo = url.searchParams.get("hide-logo");

  const [values, setValues] = useState<PriceCalculatorFormValues>({
    buSource: null,
    deliveryType: DeliveryType.AT_OFFICE,
    buConsignee: null,
    sourceLocation: null,
    consigneeAddress: null,
    paymentMode: PaymentMode.CONTADO,
    service: ShipmentService.STANDARD,
    pieces: [],
    declaredValue: 0,
    totalChargedWeight: 0,
    totalPhysicalWeight: 0,
    totalDimensionalWeight: 0,
    isToPickup: false,
  });
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [items, setItems] = useState<ItemInterface[]>([]);
  const [piecesInitialValues, setPiecesInitialValues] = useState(
    PIECES_INITIAL_VALUES
  );

  // Login
  useEffect(() => {
    const Login = async () => {
      loaderService.start();
      const userLogged = await userLogin(
        process.env.REACT_APP_SHIPMENT_APP_USER!,
        process.env.REACT_APP_SHIPMENT_APP_PASS!,
        "120"
      );
      const user = userLogged as UserInterface;
      const token = user?.token;
      localStorage.setItem("Token", token);

      const session = await CreateOrUpdateSession(user.userID, "120", "NN");
      if (session.didError) {
        console.log(session.errorMessage);
      }
      loaderService.stop();
    };

    Login();
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const buSourceCode = url.searchParams.get("bu-source-code");
    const buConsigneeCode = url.searchParams.get("bu-consignee-code");
    const deliveryTypeParam = url.searchParams.get("delivery-type");
    const paymentModeParam = url.searchParams.get("payment-mode");
    const serviceParam = url.searchParams.get("service");
    const declaredValueParam = url.searchParams.get("declared-value");
    const isToPickup = url.searchParams.get("is-to-pickup") !== null;
    const shipperLatitude = url.searchParams.get("shipper-latitude");
    const shipperLongitude = url.searchParams.get("shipper-longitude");
    const consigneeLatitude = url.searchParams.get("consignee-latitude");
    const consigneeLongitude = url.searchParams.get("consignee-longitude");
    const weightList = url.searchParams.getAll("weight").map((w) => +w);
    const heightList = url.searchParams.getAll("height").map((h) => +h);
    const widthList = url.searchParams.getAll("width").map((w) => +w);
    const lengthList = url.searchParams.getAll("length").map((l) => +l);
    const categoryIDList = url.searchParams
      .getAll("category-id")
      .map((c) => +c);

    const buSource = businessUnits.find((b) => b.code === buSourceCode) ?? null;
    const buConsignee =
      businessUnits.find((b) => b.code === buConsigneeCode) ?? null;
    const deliveryType = deliveryTypeParam
      ? +deliveryTypeParam
      : DeliveryType.AT_OFFICE;
    const paymentMode = paymentModeParam
      ? +paymentModeParam
      : PaymentMode.CONTADO;
    const service = serviceParam ? +serviceParam : ShipmentService.STANDARD;
    const declaredValue = declaredValueParam ? +declaredValueParam : 0;

    let pieces: PieceInterface[];
    if (
      weightList.length !== heightList.length ||
      heightList.length !== widthList.length ||
      widthList.length !== lengthList.length ||
      lengthList.length !== categoryIDList.length
    ) {
      pieces = [];
    } else {
      const n_pieces = weightList.length;
      pieces = new Array(n_pieces).fill(0).map((x, i) => ({
        weight: weightList[i],
        height: heightList[i],
        width: widthList[i],
        length: lengthList[i],
        category:
          pieceCategories.find((c) => c.id === categoryIDList[i]) ??
          pieceCategories.find((c) => c.id === 49)!,
        value: 0,
      }));
    }

    const sourceLocation: LocationInterface | null =
      isToPickup && !!shipperLatitude && !!shipperLongitude
        ? {
            name: "Coordenadas geográficas",
            address: `${shipperLatitude}, ${shipperLongitude}`,
            coordinates: {
              lat: +shipperLatitude,
              lng: +shipperLongitude,
            },
          }
        : buSource?.location ?? null;

    const consigneeAddress: LocationInterface | null =
      deliveryType === DeliveryType.AT_HOME &&
      !!consigneeLatitude &&
      !!consigneeLongitude
        ? {
            name: "Coordenadas geográficas",
            address: `${consigneeLatitude}, ${consigneeLongitude}`,
            coordinates: {
              lat: +consigneeLatitude,
              lng: +consigneeLongitude,
            },
          }
        : buConsignee?.location ?? null;

    const afunction = async () => {
      const response = await getWeights(236, 10, pieces);

      setValues((v) => ({
        ...v,
        buSource,
        buConsignee,
        sourceLocation,
        consigneeAddress,
        deliveryType,
        paymentMode,
        service,
        declaredValue,
        pieces,
        isToPickup,
        totalChargedWeight: response.model?.chargedWeight ?? 0,
        totalPhysicalWeight: response.model?.physicalWeight ?? 0,
        totalDimensionalWeight: response.model?.dimensionalWeight ?? 0,
      }));
    };

    afunction();
  }, [businessUnits, pieceCategories]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 items-center justify-center">
        <img
          src={logo}
          alt="Logo TEALCA"
          className={classNames("h-32 mt-8", hideLogo !== null && "hidden")}
        />
      </div>

      <div className="p-4 shadow-lg rounded-lg mx-2 md:mx-5 mb-8 mt-8 flex flex-col lg:flex-row gap-10 border border-gray-300 bg-white">
        <div className="flex flex-1 flex-col pb-10">
          <PriceCalculatorForm
            values={values}
            editMode={editMode}
            editIndex={editIndex}
            initialValues={piecesInitialValues}
            clearItems={() => setItems([])}
            setValues={setValues}
            setEditMode={setEditMode}
            setInitialValues={setPiecesInitialValues}
          />
        </div>

        <div className="flex flex-1 flex-col">
          <PriceCalculatorResume
            items={items}
            values={values}
            editMode={editMode}
            editIndex={editIndex}
            setItems={setItems}
            setValues={setValues}
            setEditMode={setEditMode}
            setEditIndex={setEditIndex}
            setInitialValues={setPiecesInitialValues}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceCalculator;
