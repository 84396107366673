import React, { FC, useMemo } from "react";
import { DocumentInterface } from "../../interfaces";
import PaymentTable from "./PaymentTable";

interface ShipmentPaymentsProps {
  document?: DocumentInterface;
}

const ShipmentPayments: FC<ShipmentPaymentsProps> = ({ document }) => {
  const payments = useMemo(() => document?.payments ?? [], [document]);

  return (
    <div className="flex flex-col w-full overflow-x-auto">
      <h2 className="text-lg font-semibold text-gray-700 mb-4">
        Pagos realizados
      </h2>

      {payments.length === 0 && (
        <div className="flex w-full justify-center items-center py-4">
          <p className="text-gray-400 text-center">
            No se ha aprobado ningún pago
          </p>
        </div>
      )}

      {payments.length > 0 && (
        <PaymentTable payments={payments} />
      )}
    </div>
  );
};

export default ShipmentPayments;
