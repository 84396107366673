import { FC } from "react";
import { PaymentInterface, PaymentMethodEnum } from "../../interfaces";
import classNames from "classnames";
import { LinkText } from "../../components/Buttons";
import { previewFileDO } from "../../services";
import moment from "moment";
import {
  currencyExchangeText,
  formatPaymentStatus,
  useCurrencyExchanges,
} from "../../utils";

interface PaymentRowProps {
  payment: PaymentInterface;
  index: number;
}

const PaymentRow: FC<PaymentRowProps> = ({ payment, index }) => {
  const exchanges = useCurrencyExchanges();

  const paymentMethodName = (payment: PaymentInterface): string => {
    const methodName = payment.paymentMethod.paymentMethodName;
    if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.EFECTIVO ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.VUELTO_EFECTIVO
    ) {
      if (payment.paymentMethod.currencyID === 1) {
        return `${methodName} en Bolívares`;
      }
      return `${methodName} en Dólares`;
    }

    return methodName;
  };

  return (
    <tr
      key={payment.paymentID ?? index}
      className={classNames(index % 2 === 0 && "bg-gray-50")}
    >
      <td className="px-2 py-2 text-xs text-gray-500 font-semibold">
        {paymentMethodName(payment)}
        {!!payment.creationDate && (
          <p className="text-gray-700 truncate">
            <span className="text-gray-800 font-normal hidden md:inline">Registrado: </span>{" "}
            {new Date(payment.creationDate)
              .toLocaleDateString("es-VE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace("a", "AM")
              .replace("p", "PM")
              .slice(0, -4)}
          </p>
        )}
      </td>

      <td className="px-2 py-2 text-xs text-gray-500 font-semibold hidden sm:block">
        {payment.isRetention && payment.proofOfPayment && (
          <div>
            <div className="flex gap-2">
              <p className="font-semibold text-gray-500 truncate">
                <LinkText
                  text="Ver comprobante"
                  onClick={() => {
                    previewFileDO(payment.proofOfPayment!);
                  }}
                />
              </p>
            </div>
            <p className="hidden">
              {moment(payment.paymentDate).format("YYYY-MM-DD hh:mm a")}
            </p>
          </div>
        )}
      </td>

      <td className="px-2 py-2 text-xs text-gray-500 font-semibold">
        <p>
          {payment.reference?.includes(":")
            ? payment.reference.split(":")[0]
            : payment.reference}
        </p>
        <p className="block sm:hidden">{formatPaymentStatus(payment.status)}</p>
      </td>

      <td className="px-2 py-2 text-xs text-gray-500 font-semibold hidden sm:table-cell">
        {formatPaymentStatus(payment.status)}
      </td>

      <td className="px-2 py-2 text-xs text-gray-500 font-semibold text-right">
        <div className="flex flex-col items">
          <p className="font-semibold text-gray-600 truncate">
            {currencyExchangeText(payment.amount, exchanges, "USD")}
          </p>
          <p className="text-xs text-gray-400 ">
            {currencyExchangeText(payment.amount, exchanges, "BS")}
          </p>
        </div>
      </td>
    </tr>
  );
};

export default PaymentRow;
