import React, { FC, useMemo } from "react";
import { PaymentInterface, PaymentStatusEnum } from "../../interfaces";
import PaymentRow from "./PaymentRow";
import { currencyExchangeText, useCurrencyExchanges } from "../../utils";

interface PaymentTableProps {
  payments: PaymentInterface[];
}

const PaymentTable: FC<PaymentTableProps> = ({ payments }) => {
  const exchanges = useCurrencyExchanges();

  const approvedPayments = useMemo(() => {
    return (
      payments.filter(
        (p) =>
          p.status === PaymentStatusEnum.APPROVE ||
          p.status === PaymentStatusEnum.PENDING
      ) ?? []
    );
  }, [payments]);

  const rejectedPayments = useMemo(() => {
    return (
      payments.filter(
        (p) =>
          p.status === PaymentStatusEnum.REJECT ||
          p.status === PaymentStatusEnum.CANCELED
      ) ?? []
    );
  }, [payments]);

  const totalPaid = useMemo(() => {
    return approvedPayments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0);
  }, [approvedPayments]);

  return (
    <table className="table-auto w-full">
      <thead>
        <tr>
          <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
            MÉTODO DE PAGO
          </th>
          <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs hidden sm:block">
            COMPROBANTE
          </th>
          <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
            REFERENCIA
          </th>
          <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs hidden sm:block">
            ESTADO
          </th>
          <th className="text-right px-4 py-2 font-bold text-gray-700 text-xs">
            MONTO
          </th>
        </tr>
      </thead>
      <tbody>
        {approvedPayments.length > 0 &&
          approvedPayments.map((payment, index) => (
            <PaymentRow
              key={`${payment.paymentID}-${index}`}
              payment={payment}
              index={index}
            />
          ))}

        {approvedPayments.length === 0 && (
          <tr>
            <td colSpan={5} className="py-6">
              <p className="text-gray-400 text-center">
                No hay pagos aprobados
              </p>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan={5}>
            <p className="text-gray-400 text-sm text-end truncate">
              Total pagado:
            </p>

            <div className="flex flex-col items-end">
              <p className="font-semibold text-gray-800 text-2xl">
                {currencyExchangeText(totalPaid, exchanges, "USD")}
              </p>
              <p className="text-sm text-gray-500">
                {currencyExchangeText(totalPaid, exchanges, "BS")}
              </p>
            </div>
          </td>
        </tr>

        {rejectedPayments.length > 0 && (
          <>
            <tr>
              <td colSpan={5}>
                {rejectedPayments.length > 0 && (
                  <h3 className="text-sm mt-6 font-semibold text-gray-700">
                    Pagos rechazados y anulados
                  </h3>
                )}
              </td>
            </tr>

            {rejectedPayments.map((payment, index) => (
              <PaymentRow
                key={payment.paymentID ?? index}
                payment={payment}
                index={index}
              />
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};

export default PaymentTable;
